import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    // const posts = get(this, 'props.data.allContentfulNfnPost.edges')
    const filteredPosts = get(this, 'props.data.allContentfulNfnPost')
    // console.log(filteredPosts.group[1].edges)
    const avNewsPosts = filteredPosts.group[1].edges
    const avTrucksNewsPosts = filteredPosts.group[0].edges
    
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <div className="wrapper">
            <h2 className="section-headline" id="avNews">Autonomous Vehicle News</h2>
            <ul className="article-list">
              {avNewsPosts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>

            <h2 className="section-headline" id="truckNews">Autonomous Trucks News</h2>
            <ul className="article-list">
              {avTrucksNewsPosts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNfnPost {
    group(field: feedName, limit: 4) {
      fieldValue
      edges {
        node {
          postTitle
          slug
          publishDate
          createdLocal
          feedName
          postSummary {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
  }
`
